import { createRouter, createWebHashHistory } from 'vue-router'

const mainRouters = [
  {
    path:'home',
    component:()=>import('../views/main/index.vue'),
    meta:{
      title:'展馆管理',
      role:['admin','supAdmin'],
      icon:'location'
    }
  },
  {
    path:'comment',
    component:()=>import('../views/comment/index.vue'),
    meta:{
      title:'打卡管理',
      role:['admin','supAdmin'],
      icon:'flag'
    }
  },
  {
    path:'activity',
    component:()=>import('../views/activity/index.vue'),
    meta:{
      title:'活动安排',
      role:['admin','supAdmin'],
      icon:'view-module'
    }
  },
  {
    path:'user',
    component:()=>import('../views/user/index.vue'),
    meta:{
      title:'用户管理',
      role:['supAdmin'],
      icon:'usergroup'
    }
  }
]

const routes = [
  {
    path: '/',
    redirect:'/main'
  },
  {
    path: '/login',
    name: 'login',
    component: ()=>import('../views/login/index.vue')
  },
  {
    path: '/main',
    name: 'main',
    component: () => import(/* webpackChunkName: "about" */ '../layout/index.vue'),
    redirect: '/main/home',
    children:mainRouters
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
