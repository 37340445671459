<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
#app .t-pagination__number.t-is-current{
  background-color: #24b07d;
  color: var(--td-text-color-anti);
  border-color: #42b983;
}
#app .t-pagination__number:active{
  background-color: #24b07d;
  color: var(--td-text-color-anti);
  border-color: #42b983;
}
#app .t-pagination__number:hover{
  background-color: #24b07d;
  color: var(--td-text-color-anti);
  border-color: #42b983;
}
</style>
