const Token = 'token'

export function getToken(){
    let token = localStorage.getItem(Token)
    if(token===undefined || token ===  null){
        return false
    }else {
        return JSON.parse(token)
    }
}

export function setToken(token){
    localStorage.setItem(Token,JSON.stringify(token))
    return true
}

export function delToken(){
    localStorage.removeItem(Token)
    return true
}
