import router from "./index.js";
import 'nprogress/nprogress.css'
import {getToken} from "../utils/auto";
import NProgress from 'nprogress'
import {MessagePlugin} from "tdesign-vue-next";


router.beforeEach((to,from,next)=>{
    let token = getToken()
    console.log(token)
    NProgress.start()
    if(token){
        if(to.path==='/login'){
            next('/')
        }else {
            next()
        }
    }else{
        if (to.path!=='/login'){
            MessagePlugin.warning('登录过期，请重新登录！')
            next('/login')
        }else {
            next()
        }
    }
})

router.afterEach(()=>{
    NProgress.done()
})
